import './scss/main.scss';

import $ from 'jquery';

import '@fancyapps/fancybox';

import 'jquery-validation/dist/jquery.validate';
import 'jquery-validation/dist/localization/messages_pl';
import 'jquery-validation/dist/additional-methods';

$('a[href^="#"]').on('click', (e) => {
  const target = $(e.currentTarget).attr('href');
  const { top } = $(target).offset();
  const outerHeight = $('.header').outerHeight();

  $('html, body, document').animate({
    scrollTop: top - outerHeight,
  }, 500);
});

const $form = $('#contact-form');
const $response = $('#contact-response');
const formUrl = $form.attr('action');
const formMethod = $form.attr('method') || 'POST';

$form.validate({
  rules: {
    name: 'required',
    surname: 'required',
    email: {
      required: true,
      email: true,
    },
    phone: {
      required: true,
      phonePL: true,
    },
    agree_rules: 'required',
  },
  messages: {
    phone: {
      phonePL: 'Podany numer telefonu ma nieprawidłowy format',
    },
  },
  submitHandler() {
    // disable button
    $form.find(':submit').prop('disabled', true);

    const xhr = $.ajax({
      url: formUrl,
      type: formMethod,
      data: $form.serialize(),
    });

    xhr.done((response) => {
      let message = '';

      try {
        const str = JSON.parse(response);
        message = str.message; // eslint-disable-line
      } catch (e) {
        message = response;
      }

      $form.hide();
      $response.html(message).closest('.contact-response').addClass('is-active');
    });
  },
});

$form.on('submit', (e) => {
  e.preventDefault();
});
